<template>
  <div class="page-con">
    <div class="top-text">
      <img src="@static/vip/gongxi.png" alt="">
    </div>
    <div class="get-text">获取新手大礼包!</div>
    <div class="auth-con">
      <div class="auth-round">
        <img :src="info.headimgurl != '' ? info.headimgurl : auth " alt="">
      </div>
      <span>{{ info.mobile }}</span>
    </div>
    <div class="open-btn" @click="open">
      <img src="@static/vip/open.png" alt="">
    </div>
  </div>
</template>
<script>
import UserInfo from "@/api/user";
import { BaseURL } from "@/lin/plugins/axios";
export default {
  data() {
    return {
      info: {},
      user_money: {},
      auth: require('../../../../../../public/static/images/vip/touxiang_1.png')
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      try {
        const res = await UserInfo.getRreco_codeInfo(this.$route.query.get_code);
        if (res.code == 200) {
          this.info = res.data;
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 拆红包
    async open() {
      try {
        if (this.isWeixin_status) {
          window.location.href = `${BaseURL}/wechat/user.login/inviteRegister?app_id=${this.appid}&reco_code=${this.$route.query.get_code}`
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<style scoped>
.page-con {
  width: 100%;
  height: 100%;
  background: url('../../../../../../public/static/images/vip/chai_bg.png') no-repeat;
  background-size: cover;
  background-position: 40% 0;
  overflow: hidden;
}
.top-text {
  width: 270px;
  margin-left: 32px;
  margin-top: 70px;
}
.top-text img {
  width: 100%;
  height: 100%;
}
.get-text {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: 20px;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: serif;
}
.auth-round {
  width: 70px;
  height: 70px;
  margin-top: 10px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}
.auth-round img {
  width: 100%;
  height: 100%;
}
.auth-con {
  width: 100%;
  height: 110px;
  display: flex;
  font-size: 14px;
  color: #ffffff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.open-btn {
  width: 180px;
  margin: -80px auto;
}
.open-btn img {
  width: 100%;
  height: 100%;
}
</style>